import React from 'react'
import styled from 'styled-components'

import articleBGWrapper from '../../images/parent/article_bg_wrapper.svg'

const ArticleBGWrapperLayout = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    bottom: -120px;
    background-image: url(${articleBGWrapper});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    pointer-events: none;
    z-index: 0;
    @media (max-width: 575px) {
      bottom: -40px;
    }
  }
`

const ArticleBGWrapper = (props) => {
  const { children } = props

  return <ArticleBGWrapperLayout>{children}</ArticleBGWrapperLayout>
}

export default ArticleBGWrapper
