import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { ModalBody, Row, Col } from 'reactstrap'
import styled from 'styled-components'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import { ArticleHeader2 } from '../../../components/parent/article-header'
import ArticleBGWrapper from '../../../components/parent/article-bg-wrapper'
import UsefulTools from '../../../components/parent/useful-tools'
import {
  CuriosityCloseButton,
  CuriosityModal,
} from '../../../components/parent/curiosity'

import { ArticleWrapper, ArticleContainer } from '../../../components/elements'

const ExpertWrapper = styled.div`
  margin-bottom: 45px;

  .gatsby-image-wrapper {
    height: auto;
    max-width: 125px;
  }

  p {
    font-weight: 400;
    margin-top: 5px;
  }
`

export const Expert = ({ image, name }) => (
  <ExpertWrapper>
    <Img fluid={image} alt={name} />
    <p>{name}</p>
  </ExpertWrapper>
)

const Page = ({ location }) => {
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  const data = useStaticQuery(graphql`
    query {
      ArticleHeaderImage1: file(
        relativePath: { eq: "parent/payback/article5.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool1: file(relativePath: { eq: "parent/tools/krzyzowki.png" }) {
        childImageSharp {
          fluid(maxWidth: 1390) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool2: file(relativePath: { eq: "parent/tools/kolorowanka.png" }) {
        childImageSharp {
          fluid(maxWidth: 1390) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool3: file(
        relativePath: { eq: "parent/curiosity/DESKTOP/desktop_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, maxHeight: 1342) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      CuriosityBigImage: file(
        relativePath: { eq: "parent/curiosity/DESKTOP/desktop_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      CuriosityBigImageMobile: file(
        relativePath: { eq: "parent/curiosity/MOBILE/mobile_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Expert: file(relativePath: { eq: "parent/payback/expert5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 125) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO
        title="Czy dawanie kieszonkowego się opłaca? Aleksander Naganowski"
        description="Mogłoby się wydawać, że dzieci i pieniądze to dwa odległe światy. Jeśli łączymy je w jakikolwiek sposób to najczęściej wtedy, kiedy uświadamiamy sobie, ile nas kosztuje utrzymanie dziecka lub kiedy komentujemy zgłaszane przez dziecko potrzeby finansowe."
        linkImage={data.ArticleHeaderImage1.childImageSharp.fluid.src}
      />

      <ArticleHeader2
        image={data.ArticleHeaderImage1.childImageSharp.fluid}
        title={<>Czy dawanie kieszonkowego się opłaca?</>}
        linkPrev="/strefa-rodzica/kieszonkowe"
        color="#fff"
      />

      <ArticleBGWrapper>
        <ArticleWrapper style={{ marginTop: 0, fontWeight: 300 }}>
          <ArticleContainer>
            <Row>
              <Col xs={12} className="marginTop30">
                <Expert
                  image={data.Expert.childImageSharp.fluid}
                  name="Aleksander Naganowski"
                />

                <p>
                  Na kieszonkowe warto spojrzeć nie tylko z{'\u00A0'}perspektywy
                  przydzielania konkretnej sumy pieniędzy i{'\u00A0'}tego, co
                  {'\u00A0'}można za{'\u00A0'}nią kupić, lecz również z
                  {'\u00A0'}perspektywy dawania dziecku możliwości zdobywania
                  kompetencji poprzez samodzielne zarządzanie finansami. Jeżeli
                  wydawanie pieniędzy jest świadome, to{'\u00A0'}każda otrzymana
                  i{'\u00A0'}wydana złotówka dodaje się do{'\u00A0'}kapitału
                  doświadczenia, które zaprocentuje w{'\u00A0'}dorosłym życiu.{' '}
                </p>
                <p>
                  Kieszonkowe pozwala dziecku rozwijać wiele ważnych
                  kompetencji, takich jak odraczanie przyjemności, cierpliwość,
                  planowanie rozdysponowania budżetu w{'\u00A0'}dłuższym
                  terminie (np. oszczędzanie na{'\u00A0'}konkretny cel).
                  Dziecko, które dziś będzie samodzielnie gospodarowało własnym
                  kieszonkowym, używało konta bankowego oraz analizowało swoje
                  wydatki, zdobędzie umiejętności, które będą mu{'\u00A0'}
                  niezbędne w{'\u00A0'}przyszłości. Z{'\u00A0'}kolei każda
                  strata i{'\u00A0'}zgubienie pieniędzy uruchamia proces
                  przyzwyczajania się do{'\u00A0'}myśli, że z{'\u00A0'}
                  posiadaniem pieniędzy wiąże się również kwestia
                  bezpieczeństwa, a{'\u00A0'}czasem poczucie straty.{' '}
                </p>
                <p>
                  Patrząc więc na{'\u00A0'}kieszonkowe przez pryzmat niezwykle
                  praktycznej lekcji, uważam, że jak najbardziej warto dawać je
                  {'\u00A0'}dzieciom, bo{'\u00A0'}wraz z{'\u00A0'}pieniędzmi
                  dajemy im{'\u00A0'}wymierne wartości edukacyjne.{' '}
                </p>
              </Col>
            </Row>
          </ArticleContainer>
        </ArticleWrapper>

        <UsefulTools
          items={[
            {
              title: 'Ciekawostki',
              subtitle:
                'Rozmowy dzieci z dorosłymi o pieniądzach mają wielką wartość, czyli raport PISA',
              image: data.Tool3,
              onClick: toggleModal,
            },
            {
              title: 'Kolorowanki i rebusy',
              subtitle: 'Kolorowanka',
              image: data.Tool2,
              link: '/parent/Kolorowanka%201.pdf',
            },
            {
              title: 'Gry',
              subtitle: 'Bankosłówka',
              image: data.Tool1,
              link: '/parent/Bankoslowka.pdf',
            },
          ]}
        />
      </ArticleBGWrapper>

      <CuriosityModal isOpen={modal} size="lg" toggle={toggleModal}>
        <ModalBody>
          <CuriosityCloseButton onClick={toggleModal} />
          <div />
          <Img
            fluid={data.CuriosityBigImage.childImageSharp.fluid}
            className="curiosityImage"
          />
          <Img
            fluid={data.CuriosityBigImageMobile.childImageSharp.fluid}
            className="curiosityImageMobile"
          />
        </ModalBody>
      </CuriosityModal>
    </Layout>
  )
}

export default Page
